import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { ExpandableType, IColumnType } from './Table'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import styles from './Table.module.scss'
import { Tooltip } from 'antd'
import { openNotification } from 'services/Util/openNotification'

type TableRowProps<T> = {
  data: T[]
  columns: IColumnType<T>[]
  onRowClick?: (data: T) => void
  disabledRow?: (data: T) => boolean
  expandable?: ExpandableType<T>
  selectedRows?: string[]
}

function TableRowCell<T>({ item, column }: { item: T; column: IColumnType<T> }): JSX.Element {
  const cellProps = column.onCell ? column.onCell(item) : {}

  return (
    <td className={styles.table_row_cell} {...cellProps}>
      {column.render ? column.render(column, item) : (item as any)[column.key]}
    </td>
  )
}

function TableRow<T>(props: TableRowProps<T>): JSX.Element {
  const { data, columns, onRowClick, disabledRow, expandable, selectedRows = [] } = props
  const [expandedRow, setExpandedRow] = useState<number[]>([])

  const renderArrowIcon = (item: T, itemIndex: number) => {
    if ('rowExpandable' in expandable!) {
      if (expandable?.rowExpandable?.(item)) {
        return (
          <Tooltip title='See Details' placement='bottom'>
            <ArrowIcon
              className={classNames(styles.table_row_expand_icon, {
                [styles.table_row_expand_icon_expanded]: expandable?.expandedRowsCondition
                  ? expandable.expandedRowsCondition(item)
                  : expandedRow.includes(itemIndex),
              })}
              aria-label='See Details'
              onClick={(e) => {
                e.stopPropagation()
                if (isRowDisabled(item)) {
                  openNotification({ type: 'info', message: 'Record is disabled', duration: 2 })
                } else {
                  if ('setExpandedRows' in expandable && expandable?.setExpandedRows) {
                    expandable.setExpandedRows(item)
                  } else {
                    setExpandedRow((prevState) =>
                      prevState.includes(itemIndex)
                        ? prevState.filter((res) => res !== itemIndex)
                        : [...prevState, itemIndex],
                    )
                  }
                }
              }}
            />
          </Tooltip>
        )
      }
      return null
    }
    return (
      <Tooltip title='See Details' placement='bottom'>
        <ArrowIcon
          className={classNames(styles.table_row_expand_icon, {
            [styles.table_row_expand_icon_expanded]: expandable?.expandedRowsCondition
              ? expandable.expandedRowsCondition(item)
              : expandedRow.includes(itemIndex),
          })}
          aria-label='See Details'
          onClick={(e) => {
            e.stopPropagation()
            if (isRowDisabled(item)) {
              openNotification({ type: 'info', message: 'Record is disabled', duration: 2 })
            } else {
              if (expandable?.setExpandedRows) {
                expandable.setExpandedRows(item)
              } else {
                setExpandedRow((prevState) =>
                  prevState.includes(itemIndex)
                    ? prevState.filter((res) => res !== itemIndex)
                    : [...prevState, itemIndex],
                )
              }
            }
          }}
        />
      </Tooltip>
    )
  }

  const isRowDisabled = (item: any) => {
    if (disabledRow) return disabledRow(item)
    return false
  }

  return (
    <>
      {data?.map((item: any, itemIndex) => (
        <Fragment key={`table-body-${itemIndex}`}>
          <tr
            className={classNames(styles.table_row_item, selectedRows.includes(item?.id) && styles.table_row_selected, {
              [styles.table_row_item_disabled]: isRowDisabled(item),
            })}
            data-row-disabled={disabledRow?.(item)}
            onClick={() => {
              if (isRowDisabled(item)) openNotification({ type: 'info', message: 'Record is disabled', duration: 2 })
              else onRowClick?.(item)
            }}>
            {expandable && Object.keys(expandable).length && typeof expandable?.expandedRowRender === 'function' ? (
              <td className={styles.table_row_cell}>{renderArrowIcon(item, itemIndex)}</td>
            ) : null}
            {columns.map((column, columnIndex) => (
              <TableRowCell key={`table-row-cell-${columnIndex}`} item={item} column={column} />
            ))}
          </tr>
          {typeof expandable?.expandedRowRender === 'function' &&
          (expandable?.expandedRowsCondition
            ? expandable.expandedRowsCondition(item)
            : expandedRow.includes(itemIndex)) ? (
            <tr className={classNames(styles.table_row_item, styles.table_row_item_expand)}>
              <td className={styles.table_row_cell} colSpan={columns.length + 1}>
                {expandable?.expandedRowRender(item)}
              </td>
            </tr>
          ) : null}
        </Fragment>
      ))}
    </>
  )
}

export default TableRow
