import { Col, Dropdown, MenuProps, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as ReportIcon } from 'assets/images/icons/monitor/report_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import DailyExecutiveSummary from '../Asset/BrandsDashboard/components/DailyExecutiveSummary/DailyExecutiveSummary'
import PowerInsightTabsListVertical from '../Asset/BrandsDashboard/components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'
import classNames from 'classnames'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'
import { SnippetFilter } from 'components/Monitor/SnippetFilter/SnippetFilter'
import millify from 'millify'
import { DetailsObjType, Mode, SubStore } from 'types/types'
import { FilterChips } from 'components/Monitor/FilterChips/FilterChips'

import '../Asset/BrandsDashboard/BrandDashboard.scss'
import './PowerInsights.scss'

interface Props {
  mode: Mode
  subStore: SubStore
}

const PowerInsights = (props: Props) => {
  const { subStore, mode } = props
  const location = useLocation()
  const navigate = useNavigate()
  const detailObj = location.state as DetailsObjType | null

  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('')

  const { loaderStore } = store
  const { snippetsFilter, snippetsTotal, fetchSnippets, setSnippetsFilter, fetchPowerInsights } =
    store[`${subStore}Store`]
  const { isLoadingFeed } = loaderStore

  useEffect(() => {
    if (detailObj === null) {
      navigate('../', { replace: true })
    }
    if (detailObj?.name) {
      if (detailObj?.languageKey) {
        setSnippetsFilter({
          ...snippetsFilter,
          filter_language: detailObj.languageKey === 'Default' ? 'en' : detailObj.languageKey,
        })
      }

      fetchPowerInsights(detailObj, false)
    }
  }, [detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions)])

  useEffect(() => {
    fetchSnippets()
  }, [detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions), snippetsFilter])

  useEffect(() => {
    if (detailObj?.id) {
      fetchPowerInsights(detailObj as DetailsObjType)
    }
  }, [snippetsFilter, detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions)])

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)

  const buttonHandler = (event: string) => {}

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => buttonHandler('SHARE'),
      label: (
        <div className='pi-dropdown-item'>
          <ShareIcon />
          <span>Share</span>
        </div>
      ),
    },
    {
      key: '2',
      onClick: () => buttonHandler('EDIT'),
      label: (
        <div className='pi-dropdown-item'>
          <EditIcon />
          <span>Edit Name</span>
        </div>
      ),
    },
    {
      key: '3',
      onClick: () => buttonHandler('ALERT'),
      label: (
        <div className='pi-dropdown-item'>
          {detailObj?.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
          <span>Alerts</span>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='pi-dropdown-item'>
          <ReportIcon />
          <span>Export</span>
        </div>
      ),
    },
    {
      key: '5',
      onClick: () => buttonHandler('DELETE'),
      label: (
        <div className='pi-dropdown-item'>
          <DeleteIcon />
          <span>Delete</span>
        </div>
      ),
    },
  ]

  return (
    <div className='brand-dashboard'>
      <div className='brand-dashboard-stats'>
        <div className={classNames('filters-section', 'powerinsight_mood')}>
          <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
            <ExecutiveSummaryIcon />
            Executive summary
            <ExpandIcon />
          </div>
          <div className='power-insights-options'>
            <span>Power Insights</span>
            <Dropdown menu={{ items }} placement='bottomLeft'>
              <ThreeDotsIcon />
            </Dropdown>
          </div>

          <div className='date-filter'>
            <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={subStore} addCustomDateOption={false} />
          </div>
          {isOpenExecutiveSummaryModal && <DailyExecutiveSummary onClose={closeExecutiveSummaryModal} />}
        </div>

        <Row
          gutter={24}
          justify={'start'}
          align={'top'}
          className={classNames('dashboard-widgets', 'powerinsight_dashboard', 'power-insights_main_dashboard')}>
          <Col style={{ width: '226', minWidth: '221px' }}>
            <PowerInsightTabsListVertical setSelectedPowerInsightTab={setSelectedPowerInsightTab} />
          </Col>
          <Col style={{ flex: 1 }}>
            <Row gutter={[24, 24]} className='power-insights-and-snippets'>
              <Col span={16} className='power-insights-container'>
                <Row>
                  <Col span={24} className='power-insights-components'>
                    <Outlet context={{ name: '' }} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} className='snippet-section-column'>
                <Spin spinning={isLoadingFeed} wrapperClassName='snippet-section-loader'>
                  <Row>
                    <Col span={24} className='dashboard-snippet-section'>
                      <div className='dashboard-snippet-section__container'>
                        <div className='dashboard-snippet-section__container__top-container'>
                          <div className='dashboard-snippet-section__container__header'>
                            <p className='dashboard-snippet-section__container__header__title'>Snippets Feed</p>
                            {!!snippetsTotal && (
                              <p className='dashboard-snippet-section__container__header__count'>
                                {millify(snippetsTotal || 0)} snippets
                              </p>
                            )}
                          </div>
                          <SnippetFilter
                            mode={mode}
                            activeTab={''}
                            pageType={'list'}
                            subStore={subStore}
                            showDashboardActions
                            searchPlaceholder='Search Posts...'
                          />
                          <FilterChips
                            subStore={subStore}
                            detailObj={detailObj as DetailsObjType}
                            activePowerInsightTab={selectedPowerInsightTab}
                          />
                        </div>
                        <SnippetList
                          mode={mode}
                          isDashboardSnippet
                          showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
                          searchedDataResults={snippetsTotal}
                          subStore={subStore}
                        />
                      </div>
                    </Col>
                  </Row>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default observer(PowerInsights)
