import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import millify from 'millify'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'
import { ReactComponent as LowRiskIcon } from 'assets/images/icons/monitor/low_risk_icon.svg'
import { ReactComponent as HighRiskIcon } from 'assets/images/icons/monitor/high_risk_icon.svg'
import { ConditionsDataType, ConditionsPayloadType, FlagsCountType, NarrativeListDataType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { SubStore } from 'types/types'

import './FlagPosts.scss'
import { IListItem } from 'models/models'

type RenderALLPostType = {
  subStore: SubStore
  itemData: NarrativeListDataType | AssetListObjType | IListItem
  forGridView?: boolean
  className?: string
  forAsset?: boolean
  isBookMarksListing?: boolean
  isAssetsFlags?: boolean
}

const FlagPosts = observer((props: RenderALLPostType) => {
  const { itemData, forGridView, className = '', forAsset, isAssetsFlags, isBookMarksListing } = props

  const { fetchInsightsMetrics, conditionsPayload, conditionsPayloadForAssets, selectedTab, getDate, snippetsFilter } =
    store[`${props.subStore}Store`]

  const [flagsCount, setFlagsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })

  const renderConditions = useMemo(() => {
    if (forAsset) {
      if (conditionsPayload && Object.keys(conditionsPayload).length) {
        return [conditionsPayload, (itemData as AssetListObjType).conditions]
      }
      return []
    }
    if (isAssetsFlags) {
      if (isBookMarksListing) {
        if ((itemData as IListItem)?.entity_info?.length)
          return [conditionsPayloadForAssets, { document_ids: (itemData as IListItem)?.entity_info }]
        return []
      }
      if (conditionsPayloadForAssets && Object.keys(conditionsPayloadForAssets).length) {
        if (selectedTab === 'narratives') {
          return [conditionsPayloadForAssets, { narratives: [(itemData as NarrativeListDataType).narrative_number] }]
        } else if (selectedTab === 'communities') {
          return [conditionsPayloadForAssets, { communities: [(itemData as NarrativeListDataType).community_number] }]
        } else if (selectedTab === 'watchlists') {
          const uniqueChannelUrls = (itemData as NarrativeListDataType)?.channels?.reduce(
            (accumulator: string[], item) => {
              const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
              return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
            },
            [],
          )

          return [conditionsPayloadForAssets, { channel_urls: uniqueChannelUrls }]
        }
      }
      return []
    }
    if (selectedTab) {
      if (isBookMarksListing) {
        if ((itemData as IListItem)?.entity_info?.length)
          return [conditionsPayloadForAssets, { document_ids: (itemData as IListItem)?.entity_info }]
        return []
      }
      if (conditionsPayload && Object.keys(conditionsPayload).length) {
        if (selectedTab === 'narratives') {
          return [conditionsPayload, { narratives: [(itemData as NarrativeListDataType).narrative_number] }]
        } else if (selectedTab === 'communities') {
          return [conditionsPayload, { communities: [(itemData as NarrativeListDataType).community_number] }]
        } else if (selectedTab === 'watchlists') {
          const uniqueChannelUrls = (itemData as NarrativeListDataType)?.channels?.reduce(
            (accumulator: string[], item) => {
              const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
              return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
            },
            [],
          )

          return [conditionsPayload, { channel_urls: uniqueChannelUrls }]
        }
      }
    }
    return []
  }, [selectedTab, itemData, conditionsPayload, forAsset, isAssetsFlags, conditionsPayloadForAssets])

  const insightsMetricsHandler = async () => {
    if (renderConditions.length) {
      const startDate = getDate.startDate
      const endDate = getDate.endDate
      const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
      let prevStartDate = new Date(startDate)
      let prevEndDate = new Date(startDate)
      prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
      prevEndDate.setDate(prevEndDate.getDate() - 1)
      const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
      const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

      const requestParams = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      }
      const requestParamsForPrev = {
        q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat}`,
      }
      const requestData: ConditionsDataType = {
        conditions: renderConditions as ConditionsPayloadType[],
      }

      const [currentFlagCount, prevFlagCount] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData }),
        fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData }),
      ])
      setFlagsCount({
        currentFlagCount: currentFlagCount === 'error' ? 0 : currentFlagCount,
        prevFlagCount: prevFlagCount === 'error' ? 0 : prevFlagCount,
      })
      return
    }
    setFlagsCount({
      currentFlagCount: null,
      prevFlagCount: null,
    })
  }

  useEffect(() => {
    insightsMetricsHandler()
  }, [renderConditions, snippetsFilter.days])

  const renderUI = useMemo(() => {
    const riskValue = flagsCount.currentFlagCount! - flagsCount.prevFlagCount!
    if (riskValue === 0) {
      return
    }
    if (forGridView) {
      if (riskValue > 0) {
        return (
          <>
            <span className='fpc_trending_count'>+{millify(riskValue)}</span>
            <HighRiskIcon />
          </>
        )
      }
      return (
        <>
          <span className='fpc_trending_count'>{millify(riskValue)}</span>
          <LowRiskIcon className='fpc_low_icon' />
        </>
      )
    }
    return (
      <>
        <UpArrowIcon className='fpc_arrow_icon' />
        <span className='fpc_trending_count'>{millify(Math.abs(riskValue))}</span>
      </>
    )
  }, [forGridView, flagsCount])

  const postCount = millify(flagsCount?.currentFlagCount || 0)
  const displayPostCount = postCount === '0' ? '-' : postCount

  return (
    <>
      {flagsCount.currentFlagCount === null || flagsCount.prevFlagCount === null ? (
        <div className={`flag_posts_container ${className}`}>
          <span className='fpc_count'>-</span>
          {renderUI}
        </div>
      ) : (
        <div
          className={`flag_posts_container${
            flagsCount.currentFlagCount - flagsCount.prevFlagCount < 0 ? ' flag_posts_container_down' : ''
          } ${className}`}>
          <span className='fpc_count'>{displayPostCount}</span>
          {postCount !== '0' && renderUI}
        </div>
      )}
    </>
  )
})

export default FlagPosts
